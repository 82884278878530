<script setup lang="ts">
import isEmpty from 'lodash/isEmpty'

const $props = defineProps<{
	event: App.Data.EventData
}>()

const { closeLocally } = useDialog()

const errors = useProperty<{ [key: string | number]: string }>('errors')
const form = useForm<App.Data.UploadAllowedRidersCategoriesData>({
	method: 'PUT',
	url: route('events.update-upload-allowed-riders-categories', { event: $props.event.uuid }),
	reset: false,
	fields: {
		import_file: undefined as File | undefined,
	},
	hooks: {
		success: () => {
			closeLocally()
		},
	},
})

function onFileChange(event: Event) {
	const target = event.target as HTMLInputElement
	form.fields.import_file = target.files?.[0]
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<modal-base :title="t('app.upload_file')">
		<form class="space-y-5" @submit.prevent="form.submit">
			<input type="file" accept=".xlsx" @change="onFileChange" />

			<div v-if="! isEmpty(errors)" class="max-h-[200px] overflow-y-scroll border border-red-600 bg-red-100 p-2 text-sm text-red-600">
				<ul class="list-inside list-disc">
					<li v-for="error in errors" :key="error">
						{{ error }}
					</li>
				</ul>
			</div>

			<div class="flex justify-end space-x-4">
				<button-base variant="secondary" :href="route('events.show', { event: event.uuid })">
					{{ t('app.cancel') }}
				</button-base>

				<button-base type="submit" :loading="form.processing">
					{{ t('app.import') }}
				</button-base>
			</div>
		</form>
	</modal-base>
</template>
