<script setup lang="ts">
const $props = defineProps<{
	registrations: {
		owned: App.Data.Registration.RegistrationData[]
		related: Pick<App.Data.Registration.RegistrationLineData, 'uuid' | 'event_category' | 'full_name' | 'user_name' | 'entity_name'>[]
	}
	registrationDisabledMessage: string | null
	event: App.Data.EventData
}>()

const { user } = useUser()
const createButtonText = computed(() => {
	if ($props.registrations.owned.filter((registration) => registration.status === 'concept').length) {
		return t('app.continue_registration')
	}

	if ($props.event.registration_layout === 'checkbox') {
		return t('app.make_a_registration')
	}

	return t('app.make_a_registration')
})

defineLayoutProperties({
	pageTitle: $props.event.name,
	pageTitleLink: route('organizers.show', { organizer: $props.event.organizer.uuid }),
})
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/layouts/default.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
	<page-container class="space-y-6">
		<div v-if="can(event, 'register')" class="flex space-x-4">
			<button-base :href="route('events.registrations.create', { event: event.uuid })">
				{{ createButtonText }}
			</button-base>

			<button-base v-if="user?.type === 'entity' && event.rider_organisation !== 'nff' && !event.disable_registration_import" :href="route('events.registrations.bulk-create', { event: event.uuid })">
				<i-gg-import class="h-4 w-4" aria-hidden="true" />
				{{ t('app.registration_by_down_uploading_file') }}
			</button-base>
		</div>
		<message-error v-else-if="registrationDisabledMessage" class="text-sm">
			{{ registrationDisabledMessage }}
		</message-error>

		<div>
			<heading2>{{ t('app.your_registrations') }}</heading2>
			<table-base v-if="registrations.owned.length">
				<template #head>
					<table-row>
						<table-column class="pl-0">
							{{ t('app.number') }}
						</table-column>
						<table-column>{{ t('app.status') }}</table-column>
						<table-column>{{ t('app.created_at') }}</table-column>
						<table-column />
					</table-row>
				</template>
				<table-row v-for="registration in registrations.owned" :key="registration.uuid">
					<table-column class="pl-0">
						<button-base variant="link" :href="registration.url">
							#{{ registration.id }}
						</button-base>
					</table-column>
					<table-column :class="{ 'font-bold text-green-800': registration.status === 'completed' }">
						{{ t('app.registration_status_' + registration.status) }}
					</table-column>
					<table-column>{{ registration.created_at }}</table-column>
					<table-column class="text-right">
						<template v-if="can(registration, 'view')">
							<a :href="route('events.registrations.download', { registration })" target="_blank" :title="t('app.download_invoice')" class="text-primary hover:text-primary-700">
								{{ t('app.download_invoice') }}
							</a>

							<span class="px-2 text-gray-400">|</span>
						</template>
						<button-base variant="link" :href="registration.url">
							{{ t('app.view') }}
						</button-base>
					</table-column>
				</table-row>
			</table-base>
			<p v-else class="text-md mt-2">
				<template v-if="can(event, 'register')">
					{{ t('app.no_registrations_yet') }}
					<button-base variant="link" :href="route('events.registrations.create', { event: event.uuid })">
						{{ createButtonText }}
					</button-base>
				</template>
				<template v-else>
					{{ t('app.no_registrations_found') }}
				</template>
			</p>
		</div>

		<div v-if="registrations.related?.length">
			<heading2>{{ t('app.related_registrations') }}</heading2>
			<p class="mt-2 text-sm">
				{{ t('app.related_registrations_description') }}
			</p>

			<table-base>
				<template #head>
					<table-row>
						<table-column class="pl-0 font-bold">
							{{ t('app.category') }}
						</table-column>
						<table-column class="font-bold">
							{{ t('app.for') }}
						</table-column>
						<table-column class="font-bold">
							{{ t('app.by') }}
						</table-column>
					</table-row>
				</template>
				<table-row v-for="registrationLine in registrations.related" :key="registrationLine.uuid">
					<table-column class="pl-0">
						{{ registrationLine.event_category }}
					</table-column>
					<table-column>
						{{ registrationLine.full_name }}
					</table-column>
					<table-column>
						{{
							registrationLine.entity_name ?
								registrationLine.entity_name :
								(registrationLine.user_name ? registrationLine.user_name : '')
						}}
					</table-column>
				</table-row>
			</table-base>
		</div>
	</page-container>
</template>
